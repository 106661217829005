@import url('https://fonts.googleapis.com/css2?family=Sora&display=swap');

body{
    background-color: #050917;
    
}

*{
    font-family: 'Sora', sans-serif;
    box-sizing: border-box;
    margin:0;
    padding:0;
}

::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.849); 
    border-radius: 10px;
    
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(232, 230, 235, 0.185); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #5f0994c9; 
    transition: all .2s ease-in-out;
    transform: scale(1.2);
    
  }